import { ConnectionType } from 'graphql/generated';
import { unreachable } from 'utils/unreachable';

const connectionToConfigurationType: Record<ConnectionType, ConfigurationType> =
  {
    /* Connections configured via URL published metadata */
    [ConnectionType.OktaSaml]: 'MetadataUrl',
    [ConnectionType.Auth0Saml]: 'MetadataUrl',
    [ConnectionType.CyberArkSaml]: 'MetadataUrl',
    [ConnectionType.DuoSaml]: 'MetadataUrl',
    [ConnectionType.GenericSaml]: 'MetadataUrl',
    [ConnectionType.VMwareSaml]: 'MetadataUrl',

    /* Connections configured via XML metadata */
    [ConnectionType.JumpCloudSaml]: 'MetadataXml',
    [ConnectionType.OneLoginSaml]: 'MetadataXml',
    [ConnectionType.PingFederateSaml]: 'MetadataXml',

    /* Connections not configured for now */
    [ConnectionType.Adfssaml]: 'Manual',
    [ConnectionType.AzureSaml]: 'Manual',
    [ConnectionType.GoogleSaml]: 'Manual',
    [ConnectionType.PingOneSaml]: 'Manual',
    [ConnectionType.RipplingSaml]: 'Manual',

    /* Connections that will always require manual configuration */
    [ConnectionType.AdpOidc]: 'Manual',
    [ConnectionType.GenericOidc]: 'Manual',

    /* Connections that are instead configured globally */
    [ConnectionType.ShibbolethSaml]: 'None',
    [ConnectionType.SalesforceSaml]: 'None',
    [ConnectionType.GoogleOAuth]: 'None',
    [ConnectionType.MagicLink]: 'None',
    [ConnectionType.MicrosoftOAuth]: 'None',
  };

export type ConfigurationType =
  | 'MetadataUrl'
  | 'MetadataXml'
  | 'Manual'
  | 'None';

export const configurationTypeForConnectionType = (
  connectionType: ConnectionType,
  isCertificateMetadataInputEnabled: boolean,
): ConfigurationType => {
  const configurationType = connectionToConfigurationType[connectionType];

  /* Map `MetadataUrl` and `MetadataXml` configuration types back to
   * `Manual` if the feature flag is not enabled. This can be removed
   * once the flag has been enabled globally.
   */
  switch (configurationType) {
    case 'None':
    case 'Manual':
      return configurationType;
    case 'MetadataUrl':
    case 'MetadataXml':
      if (isCertificateMetadataInputEnabled) {
        return configurationType;
      }

      return 'Manual';
    default:
      return unreachable(configurationType);
  }
};
