import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Paragraph, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const GoogleSamlAddApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title>Step 1: Add Custom SAML Application</Title>

    <Paragraph>
      On your Google Admin dashboard, select "Apps" from the sidebar menu, and
      then select "Web and Mobile Apps" from the following list. On this page,
      select "Add App" and then "Add custom SAML app".
    </Paragraph>

    <Img
      priority
      height={440}
      src="/sso/google-saml/v1/google-saml-1-ap.png"
      width={1075}
    />

    <Confirm label="I’ve added a custom SAML app." onClick={onNextStep} />
  </Article>
);

export const GoogleSamlEnterDetails: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title>Step 2: Enter Details for your Custom App</Title>

    <Paragraph>
      Enter an App name and icon (if applicable) for {appName}, then select
      "Continue".
    </Paragraph>

    <Img
      priority
      height={732}
      src="/sso/google-saml/v1/google-saml-2-ap.png"
      width={1390}
    />

    <Confirm label="I’ve entered the App Details." onClick={onNextStep} />
  </Article>
);

export const GoogleSamlUploadIdpInformation: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onInputChange,
  onFileInput,
  onNextStep,
  validationErrors,
}) => {
  const isUseGeneratedConnectionEntityIdEnabled = useFeature(
    'useGeneratedConnectionEntityId',
  );

  return (
    <Article>
      <Title>Step 3: Upload Google IdP Information</Title>

      {isUseGeneratedConnectionEntityIdEnabled ? (
        <Paragraph>
          Enter your SSO URL, upload your Certificate, and click "Continue".
        </Paragraph>
      ) : (
        <Paragraph>
          Enter your SSO URL and Entity ID, upload your Certificate, and click
          "Continue".
        </Paragraph>
      )}

      <Img
        priority
        height={848}
        src="/sso/google-saml/v1/google-saml-3-ap.png"
        width={1385}
      />

      <Card>
        <Form
          disabled={
            !connection?.saml_idp_url ||
            !connection?.saml_x509_certs ||
            !connection?.saml_entity_id ||
            validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
          }
          isLoading={isLoading}
          isUpdate={
            !!(
              errors?.saml_idp_url ||
              errors?.saml_entity_id ||
              errors?.saml_x509_certs
            )
          }
          onSubmit={onNextStep}
        >
          <TextField
            autoFocus={true}
            error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
            label="SSO URL"
            name="saml_idp_url"
            onChange={onInputChange}
            placeholder="https://accounts.google.com/o/saml2/idp?idpid=C00l3qzl9"
            value={connection?.saml_idp_url}
          />

          {!isUseGeneratedConnectionEntityIdEnabled && (
            <TextField
              error={errors?.saml_entity_id}
              label="Entity ID"
              name="saml_entity_id"
              onChange={onInputChange}
              placeholder="https://accounts.google.com/o/saml2?idpid=C00l3qzl9"
              value={connection?.saml_entity_id}
            />
          )}

          <FileField
            error={errors?.saml_x509_certs}
            filename="Certificate"
            label="Certificate"
            name="saml_x509_certs"
            onUpload={onFileInput}
            value={connection?.saml_x509_certs?.[0]}
          />
        </Form>
      </Card>
    </Article>
  );
};

export const GoogleSamlServiceProviderDetails: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title>Step 4: Enter Service Provider Details</Title>

    <CopyInput
      label="Copy this Assertion Consumer Service Endpoint"
      value={connection?.saml_acs_url}
    />

    <Paragraph>
      Submit the Assertion Consumer Service Endpoint as the "ACS URL" and as the
      "Entity ID". Then, click "Continue".
    </Paragraph>

    <Img
      height={847}
      src="/sso/google-saml/v1/google-saml-4-ap.png"
      width={1373}
    />

    <Confirm
      label="I’ve configured the ACS URL and Entity ID in my custom SAML app."
      onClick={onNextStep}
    />
  </Article>
);

export const GoogleSamlAttributeMapping: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title>Step 5: Configure Attribute Mapping</Title>

    <Paragraph>
      Provide the following Attribute Mappings and select "Finish":
    </Paragraph>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      height={728}
      src="/sso/google-saml/v1/google-saml-5-ap.png"
      width={1380}
    />

    <Confirm
      label="I’ve finished configuring the Attribute Mapping."
      onClick={onNextStep}
    />
  </Article>
);

export const GoogleSamlUserAccess: React.FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => (
  <Article>
    <Title>Step 6: Configure User Access</Title>

    <Paragraph>
      In the created SAML app's landing page, select the "User Access Section".
    </Paragraph>

    <Img
      height={527}
      src="/sso/google-saml/v1/google-saml-6-ap.png"
      width={1443}
    />

    <Paragraph>
      Turn this service ON for the correct organizational units in your Google
      Directory setup. Save any changes. Note that Google may take up to 24
      hours to propagate these changes, and the connection may be inactive until
      the changes have propagated.
    </Paragraph>

    <Img
      height={361}
      src="/sso/google-saml/v1/google-saml-7-ap.png"
      width={1080}
    />

    <Confirm
      label="I’ve finished configuring the User Access."
      onClick={onNextStep}
    />
  </Article>
);
